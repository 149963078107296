import WOW from 'wow.js';
import AOS from 'simple-aos';

/*********************************/
/*         INDEX                 */
/*================================
 *     01.  Loader               *
 *     02.  Toggle Menu          *
 *     03.  Clickable Menu       *
 *     04.  Menu Sticky          *
 *     05.  Back to top          *
 *     06.  Active Sidebar       *
 *     07.  Wow Animation JS     *
 *     08.  Dark & Light Mode    *
 ================================*/

const wrapper = document.getElementsByClassName('page-wrapper')[0];
const content = document.getElementsByClassName('page-content')[0];
function updateScale() {
    const width = wrapper?.getBoundingClientRect().width;
    const zoom = width / 1920;
    const inverse = 1 / zoom * 100;

    wrapper?.style.setProperty('--zoom', zoom);
    wrapper?.style.setProperty('--inverse', inverse + 'vh');
    wrapper?.style.setProperty('--inversePercentage', inverse + '%');

    content?.style.setProperty('--zoom', zoom);
    content?.style.setProperty('--inverse', inverse + 'vh');

    document.querySelectorAll('div#modal-container').forEach(el => el?.style.setProperty('--zoom', zoom));

    document.getElementById('theme-switcher')?.style.setProperty('--zoom', zoom);
}

addEventListener('load', updateScale);
addEventListener('resize', updateScale);
addEventListener('openModal', updateScale);

/*********************/
/*       Loader      */
/*********************/
window.addEventListener('load', () => {
    if (document.getElementById('preloader')) {
        //document.getElementsByTagName('html')[0].classList.contains('dark') ? document.getElementById('preloader').classList.add('bg-slate-800') : document.getElementById('preloader').classList.add('bg-white');
        setTimeout(() => {
            document.getElementById('preloader').classList.add('hidden');
        }, 500);
    }
});
window.addEventListener('beforeunload', () => {
    if (document.getElementById('preloader')) {
        setTimeout(() => {
            document.getElementById('preloader').classList.remove('hidden');
        }, 0);
    }
});

/*********************/
/*    Toggle Menu    */
/*********************/
const navbarToggle = document.getElementById("navbar-toggle");

function toggleMenu() {
    navbarToggle.classList.toggle('open');
    var isOpen = document.getElementById('navigation');
    if (isOpen.style.display === "block") {
        isOpen.style.display = "none";
    } else {
        isOpen.style.display = "block";
    }
}

navbarToggle?.addEventListener("click", toggleMenu);

/*********************/
/*  Clickable menu   */
/*********************/
if (document.getElementById("navigation")) {
    var elements = document.getElementById("navigation").getElementsByTagName("a");
    for (var i = 0, len = elements.length; i < len; i++) {
        elements[i].onclick = function (elem) {
            if (elem.target.getAttribute("href") === "javascript:void(0)") {
                var submenu = elem.target.nextElementSibling.nextElementSibling;
                submenu.classList.toggle('open');
            }
        }
    }
}

/*********************/
/*   Menu Sticky     */
/*********************/
window.addEventListener('load', (e) => {
    e.preventDefault();
    const navbar = document.getElementById("topnav");
    if (navbar != null) {
        if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
            navbar.classList.add("nav-sticky");
        }
    }
})
window.addEventListener('scroll', (e) => {
    e.preventDefault();
    const navbar = document.getElementById("topnav");
    if (navbar != null) {
        if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
            navbar.classList.remove("!duration-0");
            navbar.classList.add("nav-sticky");
        } else {
            navbar.classList.remove("nav-sticky");
        }
    }
})

/*********************/
/*    Back To Top    */
/*********************/
window.onscroll = function () {
    const back_to_top_button = document.getElementById("back-to-top");
    if (back_to_top_button != null) {
        if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
            back_to_top_button.classList.add("inline-flex");
            back_to_top_button.classList.remove("hidden");
        } else {
            back_to_top_button.classList.add("hidden");
            back_to_top_button.classList.remove("inline-flex");
        }
    }
};

/*********************/
/*  Active Sidebar   */
/*********************/
(function () {
    var current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    if (current === "") return;
    var menuItems = document.querySelectorAll('.sidebar-nav a');
    for (var i = 0, len = menuItems.length; i < len; i++) {
        if (menuItems[i].getAttribute("href").indexOf(current) !== -1) {
            menuItems[i].parentElement.className += " active";
        }
    }
})();

/*********************/
/*      WoW Js       */
/*********************/
new WOW().init();
AOS.init({
    delay: 300,
});

/*********************/
/* Dark & Light Mode */
/*********************/
/**
 * Function to set a given theme/color-scheme
 *
 * @param themeName
 */
function setTheme(themeName) {
    localStorage.setItem('theme', themeName);
    document.documentElement.className = themeName;
}

/**
 * Function to toggle between light and dark theme
 */
function toggleTheme() {
    if (localStorage.getItem('theme') === 'dark') {
        setTheme('light');
    } else {
        setTheme('dark');
    }
}

/**
 * Immediately invoked function to set the theme on initial load
 */
(function () {
    if (localStorage.getItem('theme') === 'dark') {
        setTheme('dark');
        document.getElementById('theme-changer').checked = true;
    } else {
        setTheme('light');
        document.getElementById('theme-changer').checked = false;
    }
})();

const themeChanger = document.getElementById('theme-changer');
themeChanger.addEventListener('change', toggleTheme);
